.home-main-style {
  padding: "1rem 0";
  align-content: center;
  padding-bottom: 0;
  margin-top: 85px;
}

@media screen and (min-width: 600px) {
  .home-top-banner-btn {
    margin-top: 30px;
  }

  .home-main-style {
    padding: "1rem 0";
    align-content: center;
    padding-bottom: 0;
    margin-top: 125px;
  }
}
