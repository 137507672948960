.accordion-section {
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
  /* justify-content: center; */
  /* position: center; */
  /* min-height: 100vh; */
  /* background: black; */
  /* padding-bottom: 1000px; */
}

.container {
  /* position: absolute;
  top: 30%;
  width: 100%; */
  /* background */
  /* background: green; */
}
.faq-qa {
  border-radius: 10px;
}

.faq-qa:hover {
  background-color: #ff7700;
}

.faq-title {
  text-align: center;
  color: white;
  margin-top: 50px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}

.wrap {
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* width: 80%; */
  width: 100%;
  /* text-align: center; */
  cursor: pointer;
  padding-left: 10vw;
  padding-right: 10vw;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
}

.faq-question {
  padding: 1rem;
  display: inline-block;
  width: 75%;
  font-weight: 600;
  font-size: large;
}

.faq-span {
  display: inline-block;
  font-size: x-large;
  font-weight: 600;
}

.faq-span-larger {
  display: inline-block;
  font-size: xx-large;
  font-weight: 600;
}
/* p {
    background-color: black;
    color: white;
  } */
.p1 {
  /* background-color: black; */
  /* color: #ff26e6; */
  font-size: medium;
}
.dropdown {
  /* background: white; */
  color: white;
  /* margin-left: 25%; */
  margin-left: 15vw;
  margin-right: 15vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  /* width: 50%; */
  justify-content: center;
  align-items: left;
  max-width: 100%;
}

@media only screen and (max-width: 600px) {
  .accordion-section {
    padding-bottom: 10px;
  }
  .container {
    top: 15%;
  }

  .wrap {
    padding-left: 2vw;
    padding-right: 2vw;
  }

  .p1 {
    width: 100%;
  }

  .dropdown {
    margin-left: 10vw;
    width: 80vw;
  }
}
