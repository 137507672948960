.ms-header {
  display: flex;
  align-items: left;
  justify-content: left;

  font-family: "Montserrat", sans-serif;
  width: 100%;
  // height: 100vh;

  // background: linear-gradient(
  //   to right bottom,
  //   rgb(157, 215, 213),
  //   lighten(rgb(253, 115, 100), 10%)
  // );

  &__title {
    flex: 1 1 100%;
    width: 100%;

    text-align: left;
    font-size: 6.5vw;
    font-weight: 700;
    color: #fff;
    text-shadow: 0px 0px 2px rgba(black, 0.4);
  }
}

@media screen and (min-width: 600px) {
  .ms-header {
    &__title {
      font-size: 5vw;
      text-align: center;
    }
  }
}

// mobile
@media screen and (max-width: 600px) {
  .ms-header {
    height: 50px;
    &__title {
      font-size: 8vw;
      font-weight: 900;
      text-align: left;
      position: absolute;
      left: 10%;
      // margin-bottom: 50px;
      // height: 50px;
      width: 90%;
    }
  }
}

@media screen and (min-width: 900px) {
  .ms-header {
    &__title {
      font-size: 4vw;
    }
  }
}

.ms-slider {
  display: inline-block;

  height: 1.5em;
  overflow: hidden;

  vertical-align: middle;

  mask-image: linear-gradient(transparent, white, white, white, transparent);
  mask-type: luminance;
  mask-mode: alpha;

  &__words {
    display: inline-block;
    margin: 0;
    padding: 0;

    list-style: none;

    animation-name: wordSlider;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;
    animation-duration: 7s;
  }

  &__word {
    display: block;
    line-height: 1.3em;
    text-align: left;
    color: #ff7700;
  }
}

@keyframes wordSlider {
  $steps: -0%, -20%, -40%, -60%, -80%;

  @for $index from 0 to length($steps)-1 {
    $next: $index + 1;
    // math.div(100, length($steps) - 1) or calc(100 / (length($steps) - 1))
    $step: floor(100/ (length($steps)-1));
    $animation_step: floor($step * 0.2);

    #{$step*$index}%,
    #{($step*$next) - $animation_step}% {
      transform: translateY(nth($steps, $index + 1));
    }
  }

  100% {
    transform: translateY(nth($steps, length($steps)));
  }
}
