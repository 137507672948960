.guarantees-container {
  /* width: 95vw; */
  /* background-color: #1e1e1e; */
  background-color: none;
  margin: auto;
  margin-top: 2vh;
  border-radius: 15px;

  padding: 30px;
  text-align: center;
}

.guarantees-header {
  margin-bottom: 45px;
  color: white;
  /* font-size: xx-large; */
  font-weight: 800;

  font-size: 30px;
  /* font-size: 3.5vw; */
}

.step-number {
  font-size: x-large;
  font-weight: 600;
  /* color: #ff7700; */
}

.step-description {
  color: #9e9e9e;
}

.guarantees-section {
  width: 60vw;
  /* height: 100vw; */
  height: auto;

  /* width: 20vw;
  height: 55vw; */
  /* background-color: #1e1e1e; */
  /* background: linear-gradient(to right, #1e1e1e, #1e1e1e); */
  border-radius: 15px;
  padding: 10px;
  margin: auto;
  /* border: 1px solid black; */
  justify-content: center;
  align-content: center;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  color: white;
  margin-top: 10px;
}

.guarantees-section-img {
  width: 25vw;
  height: 25vw;
  margin: 30px;
}

@media screen and (min-width: 900px) {
}

@media screen and (min-width: 600px) {
  .guarantees-header {
    font-size: 30px;
    font-size: 3.5vw;
  }
  .guarantees-section {
    width: 25vw;
    /* height: 15vw; */
    /* margin: 0; */
    padding: 0px;
    /* margin-bottom: 100px; */
  }
  .guarantees-section-img {
    width: 100px;
    height: 100px;
    /* margin: 30px; */
  }
  /* .guarantees-section {
    width: 60vw;
    height: 100vw;
    margin-top: 10px;
  } */
}
