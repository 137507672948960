/* .swiperWrapper {
  :global(.swiper-pagination-bullet) {
    background: white;
    width: 1rem;
    height: 1rem;
    border-radius: 0.5rem;
    opacity: 1;
  }

  :global(.swiper-pagination-bullet-active) {
    background-color: green;
    width: 5rem;
    height: 1rem;
    border-radius: 0.5rem;
  }
}
.swiper {
  width: 100%;
  height: 75vh;
} */
.mySwiper {
  margin-top: 25px;
}
.swiper-pagination-bullet {
  background-color: white !important;
  width: 20px;
  height: 20px;
}
.swiper-pagination-bullet-active {
  background-color: white !important;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  /* background: ; */

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cpage-img {
  height: auto;
  width: 450px;
}

.cp-image {
  width: 40%;
  height: auto;
}

.cpage-header {
  color: white;
  /* font-size: xx-large; */
  font-weight: 900;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 15px;
  font-size: 45px;
}

.cpage-description {
  color: white;
  font-family: "Montserrat", sans-serif;
  font-size: x-large;
  /* min-height: 110px; */
}

.page-row-style {
  /* padding-top: 200px; */
  /* position: absolute; */
  padding-bottom: 50px;

  /* background-color: #ff7700; */
  /* margin-top: 10vh;
  margin-bottom: 10vh; */

  width: 100%;
  /* width: 90%;
   border-radius: 10px; */

  height: 85vh;
}

.page-row-bg {
  /* padding-top: 15px; */
  border-radius: 15px;
  width: 95vw;
  height: 65%;
  padding-top: 50px;
  background-color: #ff7700;
  overflow: visible;
  align-items: center;
  position: absolute;
  top: 0;
}

.features-img {
  border-radius: 10px;
}

.floating {
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;

  /* margin-left: 30px;
  margin-top: 5px; */
}

/* .swiper-overflow {
  overflow: visible;
} */

@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 15px);
  }
  100% {
    transform: translate(0, -0px);
  }
}

@media screen and (min-width: 1200px) {
  .page-row-style {
    height: 95vh;
    /* width: 95vw; */
    /* height: 95vh; */
    /* padding-top: 50px; */
  }
  .cpage-img {
    height: auto;
    width: 850px;
  }

  .cpage-description {
    margin-left: 10%;
    margin-right: 10%;
  }
  .cpage-header {
    font-size: 55px;
  }
}

@media screen and (max-width: 600px) {
  .cpage-header {
    color: white;
    /* font-size: xx-large; */
    font-weight: 900;
    font-family: "Montserrat", sans-serif;
    margin-bottom: 15px;
    /* margin-top: 10px; */
    font-size: 30px;
  }

  .cpage-description {
    color: white;
    font-family: "Montserrat", sans-serif;
    font-size: large;
    margin-right: 10%;
    margin-left: 10%;
    /* max-width: 75%; */
  }
}
