h1 {
  font-family: "Montserrat", sans-serif;
}
h2 {
  font-family: "Montserrat", sans-serif;
}
.header-header {
  color: white;
}

.header-container {
  width: 95vw;
  background-color: #1e1e1e;
  margin: auto;
  margin-top: 6vh;
  border-radius: 15px;
  padding: 30px;
}

.header-btn {
  width: 25vw;
  min-width: 200px;
  height: 3vw;
  min-height: 50px;
  border-radius: 100px;
  background: linear-gradient(to right, #ff9901, #e16036, #e72b22);
  color: white;
  font-family: "Montserrat", sans-serif;
  margin: 3vw;
}
.no-bs-border,
.no-bs-border:focus,
.no-bs-border:active {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  font-size: medium !important;
}

h1.header-header {
  font-size: 5vw;
}
h2.header-header-2 {
  text-align: center;
  font-size: 4vw;
  color: gray;
}

.floating {
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 15px);
  }
  100% {
    transform: translate(0, -0px);
  }
}

.header-img-container {
  text-align: right;
  overflow: hidden;
}

@media screen and (min-width: 600px) {
  h1.header-header {
    font-size: 2rem;
  }
  h2.header-header-2 {
    font-size: 1.5rem;
  }
}
.header-img {
  display: none;
}
@media screen and (min-width: 992px) {
  .header-container {
    width: 95vw;
    margin-top: 75px;
  }
  .header-img {
    display: block;
    position: absolute;
    top: 100px;
    right: 5vw;
    height: 55vh;
    min-height: 750px;
    width: auto;
  }
}

@media screen and (min-width: 1600px) {
  .header-img-container {
    text-align: center;
  }
  .header-img {
    width: auto;
    right: 10vw;
    height: 60vh;
  }
}
@media screen and (min-width: 2000px) {
  .header-img {
    height: 65vh;
  }
}
