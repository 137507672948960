.join-container {
  margin: auto;
  margin-top: 2vh;
  border-radius: 15px;

  text-align: center;
  color: white;
  font-family: "Montserrat", sans-serif;
}

.join-header {
  margin-bottom: 45px;
}

.join-input {
  border-radius: 100px;
  padding: 15px;
  padding-left: 20px;
  max-width: 600px;
  margin: auto;
  margin-bottom: 15px;
  background-color: #323232;
  color: white;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #707070;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #707070;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #707070;
}

.join-btn {
  width: 25vw;
  max-width: 300px;
  min-width: 150px;
  height: 50px;
  border-radius: 100px;
  background: linear-gradient(to right, #f12711, #ff6200, #ff7700, #ffa200);
  transition: 0.5s;
  color: #fafafa;
  font-family: "Montserrat", sans-serif;
  font-size: large;
  font-weight: 600;
  margin: auto;
  margin-top: 20px;
  z-index: 1;
  opacity: 1;
  margin-bottom: 0px;
  position: relative;
  bottom: 0px;
  margin-right: 2px;
  margin-left: 2px;
}
.join-btn:hover {
  bottom: 3px;
  font-size: x-large;
}
.join-btn:active {
  bottom: 0px;
}

.download-btn {
  /* display: block; */
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50vw;
  max-width: 400px;
  min-width: 200px;
  height: 50px;
  border-radius: 100px;
  background: transparent;
  border: 3px solid #ff6200;
  /* border-width: 3px;
  border-color: #ff6200; */
  /* background: linear-gradient(to right, #f12711, #ff6200, #ff7700, #ffa200); */
  transition: 0.5s;
  color: #ff6200;
  font-family: "Montserrat", sans-serif;
  font-size: medium;
  font-weight: 600;
  margin: auto;
  margin-top: 20px;
  z-index: 1;
  opacity: 1;
  margin-bottom: 0px;
  position: relative;
  bottom: 0px;
}

.cancel-btn {
  /* display: block; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50vw;

  height: 50px;
  border-radius: 100px;
  background: transparent;
  border: 3px solid #ff6200;
  /* border-width: 3px;
  border-color: #ff6200; */
  /* background: linear-gradient(to right, #f12711, #ff6200, #ff7700, #ffa200); */
  transition: 0.5s;
  color: #ff6200;
  font-family: "Montserrat", sans-serif;
  font-size: medium;
  font-weight: 600;
  margin: auto;
  margin-top: 20px;
  z-index: 1;
  opacity: 1;
  margin-bottom: 0px;
  position: relative;
  bottom: 0px;

  max-width: 300px;
  min-width: 150px;
  margin-right: 2px;
  margin-left: 2px;
  width: 25vw;
  border-color: #f12711;
  color: #f12711;
}

.download-btn:hover {
  cursor: pointer;
  bottom: 3px;
  color: white;
  background-color: #ff6200;
}
.cancel-btn:hover {
  /* border-color: #f12711; */
  background-color: #f12711;
  color: white;
}
.download-btn:active {
  bottom: 0px;
}

.join-img {
  width: 15vw;
  margin: auto;
  margin-bottom: 15px;
}

.no-bs-border,
.no-bs-border:focus,
.no-bs-border:active {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  font-size: medium !important;
}

@media screen and (max-width: 600px) {
  .join-img {
    width: 30vw;
  }
  .join-input {
    width: 80vw;
    max-width: 500px;
  }
  .download-btn {
    width: 75vw;
  }
}
