.footer-container {
  width: 100% !important;
  /* height: 70vh; */
  text-align: center;
  padding-top: 55px;
  padding-bottom: 55px;
  margin-top: 2vh;
  background-color: #1e1e1e !important;
  color: white;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 0 !important;
}
/* .footer-wrapper {
  padding-top: 20px;
} */

.footer-text {
  font-weight: 100;
  font-size: small;
  text-align: center;
}

.footer-links {
  text-align: right;
}
.footer-link {
  text-decoration: none;
  color: darkgray;
  font-size: small;
  margin-bottom: 0 !important;
  padding: 0 !important;
}

.powered-text {
  margin-bottom: 50px;
}

.footer-links-title {
  margin: 0;
}

.footer-company-name {
  display: inline;
}

.footer-company-icon {
  display: inline;
  width: 20px;
  margin-right: 5px;
}

.footer-icons {
  text-align: center;
  padding-top: 2vh;
  font-size: x-large;
}

.footer-icon {
  padding-left: 10px;
  padding-right: 10px;
  color: #ffffff;

  padding-bottom: 10px;
}

.footer-icon:hover {
  color: lightblue;
}
.cb-logo {
  width: 120px;
  height: auto;
  /* margin-left: 37vw; */
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  display: inline-block;

  /* position: relative;
    left: 100px; */
}

.cb-logo:hover {
  /* opacity: 50%; */
}

.social {
  /* padding: 20px; */
  font-size: 30px;
  width: 50px;
  text-align: center;
  text-decoration: none;
  /* margin: 5px 2px; */
  margin-left: 15px;
  margin-right: 15px;
  color: #ff7700;

  padding-bottom: 15px;

  /* font-size: 100px; */
}
.social:hover {
  text-decoration: none;
  opacity: 0.7;
}

.footer-link {
  color: #ff7700;
}
.footer-link:hover {
  color: #e16036;
}

@media only screen and (min-width: 600px) {
  .social {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
  }
}
