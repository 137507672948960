.howitworks-container {
  width: 100%;
  /* background-color: #1e1e1e; */
  background-color: none;
  margin: auto;
  margin-top: 2vh;
  border-radius: 15px;

  padding: 30px;
  /* text-align: center; */
}

.howitworks-header {
  margin-bottom: 45px;
  color: white;
  /* font-size: xx-large; */
  font-size: 30px;
  font-size: 3.5vw;
  /* font-size: 25%; */
  font-weight: 800;
  text-align: center;
}

.step-number {
  font-size: xx-large;
  font-weight: 700;
  /* text-align: center; */
  /* color: #ff7700; */
}

.step-description {
  /* text-align: center; */
  color: #9e9e9e;
  font-size: x-large;
}

.howitworks-section {
  /* min-width: 20vw;
  min-height: 20vw; */
  height: 20vw;
  width: 20vw;
  background-color: #1e1e1e;
  /* background: linear-gradient(to right, #1e1e1e, #1e1e1e); */
  border-radius: 15px;
  padding: 30px;
  margin: auto;
  /* border: 1px solid black; */
  /* align-content: center; */
  text-align: center;
  font-family: "Montserrat", sans-serif;
  color: white;
}

.howitworks-section-img {
  align-self: flex-start;
  width: 5vw;
  height: 5vw;
  margin: 10px;
  margin-bottom: 20px;
  /* margin-left: 2.5vw; */
  /* margin: auto; */
}

@media screen and (max-width: 1600px) {
  .step-description {
    font-size: medium;
  }
}
@media screen and (max-width: 1400px) {
  .howitworks-section {
    width: 45vw;
    height: 25vw;
    margin-top: 15px;
  }
  .step-description {
    font-size: large;
  }
}
@media screen and (max-width: 900px) {
  .howitworks-section {
    width: 50vw;
    height: 50vw;
  }

  .howitworks-section {
    margin-top: 15px;
  }
}

@media screen and (max-width: 600px) {
  .header-container {
    margin-top: 1vh;
  }

  .howitworks-header {
    font-size: 30px;
  }

  .howitworks-section {
    width: 80vw;
    height: 80vw;
    margin-top: 10px;
  }

  .howitworks-section-img {
    width: 25vw;
    height: 25vw;
    /* margin: auto; */
  }
}
