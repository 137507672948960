.investor-photo {
  /* width: 200px; */
  /* height: 200px; */
  border-radius: 4px;
  object-fit: cover;
  object-position: top;
}

.marquee-container {
  width: 95vw;
  background-color: #1e1e1e;

  margin: auto;
  margin-top: 2vh;
  /* margin-left: 2.25vw; */
  /* margin-right: 2.5vw; */

  border-radius: 10px;
  /* height: 10vh; */
}

@media screen and (max-width: 600px) {
  .marquee-container {
    /* margin-top: 3vh; */
  }
}
