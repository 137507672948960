.top-banner-container {
  width: 95vw;
  background-color: #1e1e1e;
  margin: auto;
  border-radius: 15px;
  padding: 10px;
  text-align: center;
  background: linear-gradient(to right, #f12711, #ff6200, #ff7700, #ffa200);
}
.top-banner-text {
  color: white;
}

.top-banner-btn {
  background: linear-gradient(to right, #f12711, #ff6200, #ff7700, #ffa200);

  transition: 0.5s;
  color: #fafafa;
  font-family: "Montserrat", sans-serif;
  font-size: large;
  font-weight: 600;
  text-decoration: none;
  margin: auto;
  max-width: 600px;
}

.top-banner-btn:hover {
  cursor: pointer;
}
