.bottom-strip {
  display: flex;
  flex-direction: row;
  justify-content: last baseline baseline;
  margin-top: 35px;
}

.logo-container {
  position: absolute;
  right: 0;
  margin-right: 10px;
  margin-top: 2px;
}

.flex-item {
  display: flex;
  flex: 1;
}
.nav-underline {
  text-decoration: underline;
}
.nav-top-banner {
  position: absolute;
  padding: 5px;
  width: 100%;
  text-align: center;
  background-color: #ff7700;
  text-decoration: none;
  color: white;
  font-size: medium;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  text-transform: none;
  white-space: nowrap;
}

.nav-top-banner:hover {
  color: #2c2c2c;
  cursor: pointer;
}

.no-bs-border,
.no-bs-border:focus,
.no-bs-border:active {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  font-size: medium !important;
}

.dropdown-item {
  color: #e1e1e1 !important;
}

.dropdown-toggle {
  font-size: medium;
}

.topnav-company-name {
  color: black;
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
  text-decoration: none !important;
}

/* !important for extra small screens  */
@media only screen and (max-width: 300px) {
  .topnav-company-name {
    font-size: small;
    margin-left: 65px !important;
  }
}
@media screen and (max-width: 340px) {
  .nav-top-banner {
    font-size: 8pt !important;
  }
}

.topnav {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: #121212;
  position: fixed;
  top: 0;
  width: 100%;
  height: 115px;
  z-index: 4;
  text-transform: uppercase;
  color: rgb(63, 63, 63);
  font-family: "Raleway", sans-serif;
  text-decoration: none !important;
  transition: height 0.5s;
  transition: height 0.5s;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.nav-link-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #2c2c2c;
  margin-top: 10px;
  border-radius: 100px;
  padding: 5px;
  height: 60px;
  text-align: center;
  flex: 1;
}
.nav-link {
  flex: 1;
  width: 150px;
  margin: 5px;
  padding: 10px;
  text-decoration: none;
  border-radius: 100px;
  color: #9e9e9e;
  text-align: center;
}

.nav-link:hover {
  background: linear-gradient(to right, #ff9901, #e16036, #e72b22);
  color: white;
  cursor: pointer;
}
.nav-link:focus {
  background: linear-gradient(to right, #ff9901, #e16036, #e72b22);
  color: white;
  cursor: pointer;
}
.nav-link:active {
  background: linear-gradient(to right, #ff9901, #e16036, #e72b22);
  color: white;
  cursor: pointer;
}
.nav-link.active {
  background: linear-gradient(to right, #ff9901, #e16036, #e72b22);
  color: white;
}

.topnav-logo {
  height: 60px;
  width: auto;

  position: absolute;
  top: 45px;
  left: 80px;
}

.topnav-logo:hover {
  opacity: 50%;
}

.topnav .icon {
  display: none;
}

.hamburger-icon {
  display: none !important;
}

.top-strip-number:hover {
  color: #39a0ed !important;
  text-decoration: none !important;
}

.mobile-nav-item {
  display: none;
}

@media screen and (max-width: 1200px) {
  .temp-div {
    display: none;
  }

  .nav-link-container {
    margin-right: 10px;
  }
}

@media screen and (max-width: 850px) {
  .nav-top-banner {
    font-size: small;
  }
  .topnav-logo {
    height: 30px;
    width: auto;
    position: absolute;
    top: 40px;
    left: 20px;
  }

  .bottom-strip {
    display: block;
    display: flex;
    flex-direction: row;
  }
  .vg-logo {
    width: 0px;
  }
  .topnav-company-name {
    margin-left: 0px;
  }
  .topnav-item {
    padding: 12px 25px;
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
  }
  .top-nav-brand {
    padding-top: 2px !important;
  }
  .topnav-item.active {
    color: white;
    font-weight: bold;
    text-decoration: none !important;
    background-color: #0288d1;
  }
  .topnav.responsive {
    position: fixed;
    height: 165px;
    transition: height 0.5s;
  }
  .topnav.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }

  .hamburger-icon {
    position: absolute;
    right: 0;
    top: 30px;
  }

  .top-strip {
    display: none;
  }
  .mobile-nav-spacer {
    height: 25vh;
  }
  .topnav-item:not(:first-child) {
    display: none;
  }

  .topnav-item {
    float: right;

    display: block;
  }

  .topnav {
    height: 79px;
  }
  .topnav-first {
    display: block !important;
    float: left;
    margin-top: 12px;
  }

  .hamburger-icon {
    float: right;
    display: block !important;
  }

  .temp-div {
    display: block;
  }

  .nav-link-container {
    position: absolute;
    margin-top: 55px;

    margin-right: 0;
    height: 55px;
    width: 80vw;
    left: 10vw;
  }
  .nav-link {
    margin: auto;
  }
}
